import { generateMedia } from 'styled-media-query'
import _ from 'lodash'
import useWindowSize from 'react-use/lib/useWindowSize'

export const breakpoints = {
  sm: 0,
  md: 601,
  lg: 1120,
  header: 800
}

const breakpointWithUnits = _.reduce(
  breakpoints,
  (memo, breakpoint, key) => {
    memo[key] = `${breakpoint}px`
    return memo
  },
  {}
)

const media = generateMedia(breakpointWithUnits)

media.forEach = ({ values, getStyle }) => {
  if (!values || !getStyle) return ''
  return Object.keys(values).map((bp) => {
    return media.greaterThan(bp)`${getStyle(values[bp])}`
  })
}

// Example usage:
// const Comp = styled.div`
//   ${media.forEach({
//     values: { sm: 'red', md: 'blue', lg: 'green' },
//     getStyle: (val) => `color: ${val};`
//   })}
// `

export const useBreakpoint = () => {
  const { width } = useWindowSize()
  const lt = (bp) => width <= breakpoints[bp]
  const gt = (bp) => width > breakpoints[bp]
  return { lt, gt }
}

export default media
