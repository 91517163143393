import NextLink from 'next/link'
import _ from 'lodash'
import styled from 'styled-components'

import { hyperlink, white, black } from '../config/theme/colors'
import media from '../config/theme/media'

export const Anchor = styled.a`
  color: ${({ inverse, color }) => color || (inverse ? white() : hyperlink())};
  text-decoration: ${({ noDecoration }) => noDecoration ? 'none' : 'underline'};
  ${({ noWrap }) => noWrap && 'white-space: nowrap;'};
  font-weight: normal;
  letter-spacing: 0;

  &:hover {
    cursor: pointer;
    color: ${black()};
  }

  font-size: inherit;
`

const Link = ({ children, href = '/', target, newPageIfExternal, ...props }) => {
  if (_.includes(href, ':')) {
    return (
      <Anchor href={href} target={target || (newPageIfExternal && '_blank')} {...props}>
        {children}
      </Anchor>
    )
  }

  return (
    <NextLink href={href} passHref>
      <Anchor {...props} target={target}>
        {children}
      </Anchor>
    </NextLink>
  )
}

export default Link
