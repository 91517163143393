export const primary = (opacity) => `rgba(255, 218, 0, ${opacity || 1})`
export const secondary = (opacity) => `rgba(29, 112, 184, ${opacity || 1})`
export const alert = (opacity) => `rgba(186, 0, 0, ${opacity || 1})`
export const warning = (opacity) => `rgba(251, 201, 0, ${opacity || 1})`
export const black = (opacity) => `rgba(0, 0, 0, ${opacity || 1})`
export const grey = (opacity) => `rgba(247, 247, 247, ${opacity || 1})`
export const darkGrey = (opacity) => `rgba(200, 200, 200, ${opacity || 1})`
export const white = (opacity) => `rgba(255, 255, 255, ${opacity || 1})`
export const blue = (opacity) => `rgba(29, 112, 184, ${opacity || 1})`
export const hyperlink = (opacity) => `rgba(29, 112, 184, ${opacity || 1})`

export const primaryOffset = black()
export const unselectedBackground = white()
export const selectedBackground = black(0.2)

export const cookiePolicyBgColor = grey()
export const cookiePolicyIconBgColor = primary()
export const cookiePolicyIconColor = black()
export const cookiePolicySliderBgColour = grey()

export const unselectedTabBackground = grey()
export const selectedTabBackground = white()

export const buttonBgColorPrimary = black()
export const buttonTextColorPrimary = white()

export const buttonHoverColorSecondary = grey(0.7)
export const buttonHoverColorPrimary = black(0.7)

export const buttonBgColorSecondary = white()
export const buttonTextColorSecondary = black()

export const switchBgColor = primary()
export const switchTextColor = black()
export const switchButtonColor = black()

export const bodyFontColor = black()
export const lightTextColor = grey()
export const errorTextColor = alert()
export const disabledTextColor = black(0.5)
export const placeholderTextColor = disabledTextColor

export const searchBarBorderColor = '#979797'

export const inputFontColor = '#626A6E'
export const inputBorderColor = '#000000'
export const inputHelpTextColor = '#616161'
export const backgroundColor = 'transparent'

export const tableHeadBackgroundColor = primary()
export const tableHeadFontColor = black()
export const tableRowOffsetColor = grey()

export const navBgColor = grey()
